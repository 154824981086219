import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
import IconButton from "@/components/buttons/IconButton.vue";
import Icon from "@/components/icon/Icon.vue";
import { Events } from "@/events/events";
import { deleteSpeach, goToMonitorSpeach, goToVersioning, goToWorkflowAuthor, gotToWorkflow, refresh } from "@/util/routeUtil";
import { confirmation } from "@/util/dialog";
let MenuSpeachCard = class MenuSpeachCard extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "speach", void 0);
  }
  goToMonitorSpeach() {
    goToMonitorSpeach(this.speach);
  }
  goToVersioning() {
    goToVersioning(this.speach);
  }
  dupplicateCour() {
    trig(Events.DUPLICATE_COUR_THUMBNAIL, {
      speachID: this.speach.speachID
    });
  }
  goToWorkflow() {
    if (this.speach.canApprove) {
      gotToWorkflow(this.speach);
    } else {
      goToWorkflowAuthor(this.speach);
    }
  }
  deleteSpeach() {
    confirmation(this.$t('thumbnail.menu.archiveConfirm'), '', this.$t('messageBox.ok'), this.$t('messageBox.cancel')).then(() => {
      deleteSpeach(this.speach);
      refresh();
    });
  }
};
__decorate([Prop({
  required: true
})], MenuSpeachCard.prototype, "speach", void 0);
MenuSpeachCard = __decorate([Component({
  components: {
    Icon,
    IconButton
  }
})], MenuSpeachCard);
export default MenuSpeachCard;