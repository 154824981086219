import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
import Icon from "@/components/icon/Icon.vue";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
let IconButton = class IconButton extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "className", void 0);
    _defineProperty(this, "icon", void 0);
    _defineProperty(this, "size", void 0);
    _defineProperty(this, "disabled", void 0);
    _defineProperty(this, "color", void 0);
    _defineProperty(this, "tooltip", void 0);
    _defineProperty(this, "tooltipPosition", void 0);
    _defineProperty(this, "text", void 0);
  }
  click() {
    if (!this.disabled) {
      this.$emit("click");
    }
  }
};
__decorate([Prop({
  default: ''
})], IconButton.prototype, "className", void 0);
__decorate([Prop({
  required: true
})], IconButton.prototype, "icon", void 0);
__decorate([Prop({
  default: 'default'
})], IconButton.prototype, "size", void 0);
__decorate([Prop({
  default: false
})], IconButton.prototype, "disabled", void 0);
__decorate([Prop({
  default: 'primary'
})], IconButton.prototype, "color", void 0);
__decorate([Prop({
  default: ''
})], IconButton.prototype, "tooltip", void 0);
__decorate([Prop({
  default: ''
})], IconButton.prototype, "tooltipPosition", void 0);
__decorate([Prop({
  default: ''
})], IconButton.prototype, "text", void 0);
IconButton = __decorate([Component({
  components: {
    WToolTip,
    Icon
  },
  emits: ["click"]
})], IconButton);
export default IconButton;