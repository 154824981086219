import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import StandardButton from "@/components/buttons/StandardButton.vue";
import prettyMilliseconds from "pretty-ms";
import axios from "axios";
import { Component, Prop, Vue } from "vue-facing-decorator";
import WElmessage from "@/components/wrappers/w-elmessage.vue";
import WDialog from "@/components/wrappers/w-dialog.vue";
import LightButton from "@/components/buttons/LightButton.vue";
let AIAdminPanel = class AIAdminPanel extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "limit", void 0);
    _defineProperty(this, "usage", void 0);
    _defineProperty(this, "realLimit", "0");
    _defineProperty(this, "showConfirm", false);
  }
  mounted() {
    this.realLimit = this.limit;
  }
  get usageText() {
    return prettyMilliseconds(parseFloat(this.usage) * 1000);
  }
  get pourcentageUsage() {
    return parseFloat(this.usage) / parseFloat(this.realLimit) * 100;
  }
  get limitText() {
    return prettyMilliseconds(parseFloat(this.realLimit) * 1000);
  }
  buyTime() {
    axios.put('/api/v2/administration/ai/addTime', {
      time: 3600
    }).then(response => {
      this.realLimit = response.data;
    });
  }
};
__decorate([Prop({
  required: true
})], AIAdminPanel.prototype, "limit", void 0);
__decorate([Prop({
  required: true
})], AIAdminPanel.prototype, "usage", void 0);
AIAdminPanel = __decorate([Component({
  components: {
    LightButton,
    WDialog,
    WElmessage,
    StandardButton
  }
})], AIAdminPanel);
export default AIAdminPanel;